<template>
    <div class="datePicker">

        <div class="header">
            <div class="month-name">{{ currentMonth }} {{ currentYear }}</div>
            <button @click="prevMonth"><i class="fa-solid fa-chevron-left"></i></button>
            <button @click="nextMonth"><i class="fa-solid fa-chevron-right"></i></button>
        </div> 


        <div class="weekPickerDays">
            <div class="week">
                <div></div>
                <div class="buttonHolder" :key="dayKey" v-for="(day, dayKey) of days">
                    <div class="day subtle2">{{ day }}</div>
                </div>
            </div>
            <div class="week" :key="key" v-for="(week, key) of weekDays" :class="{ 'active' : key == yearWeek }" @click="selectYearWeek(key)">
                <div class="buttonHolder">
                    <div class="day subtle weekNumber">{{ week.weekNumber }}</div>
                </div>
                <div class="buttonHolder" :key="dayKey" v-for="(day, dayKey) of week.list">
                    <div class="day" :class="{ 'subtle' : day.subtle, 'active' : day.date == currentDate }">{{ day.day }}</div>
                </div>
            </div>
        </div>
        

    </div>
</template>

<script>
import moment from 'moment'

    export default {
        props: ['modelValue'],
        data() {
            return {
                date: this.modelValue ? moment(this.modelValue, "YYYYWW") : moment(),
                days: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
                yearWeek: this.modelValue || moment().year() + "W" + moment().isoWeek()
            }
        },
        methods: {
            prevMonth() {
                let date = this.date.clone();
                date.subtract(1, 'months');
                this.date = date;
            },
            nextMonth() {
                let date = this.date.clone();
                date.add(1, 'months');
                this.date = date;
            },
            selectYearWeek(yearWeek) {
                this.yearWeek = yearWeek;
                this.$emit('select');
            }
        },
        computed: {
            currentMonth() {
                return this.date.format("MMMM");
            },
            currentYear() {
                return this.date.format("YYYY");
            },
            currentDate() {
                return moment().format("DD/MM/YYYY");
            },
            weekDays() {
                let date = this.date.clone();

                let numberOfDays = date.daysInMonth();
                
                let date1 = date.clone().date(1).startOf('isoWeek');
                let date2 = date.clone().date(numberOfDays).endOf('isoWeek').add(1, 'days');

                let weeks = {};
                while(date1.format("DD/MM/YYYY") != date2.format("DD/MM/YYYY")) {
                    let yearWeek = date1.clone().startOf('isoWeek').year() + "W" + date1.isoWeek();
                    weeks[yearWeek] = weeks[yearWeek] || { list: [], weekNumber: "W" + date1.isoWeek() };
                    let subtle = this.date.month() != date1.month() ? true : false;
                    weeks[yearWeek].list.push({
                        day: date1.date(),
                        subtle: subtle,
                        date: date1.format("DD/MM/YYYY")
                    });
                    date1.add(1, 'day');
                }


                return weeks;
            }
        },
        mounted() {
            this.$emit("update:modelValue", this.yearWeek);
        },
        watch: {
            modelValue() {
                this.date = this.modelValue ? moment(this.modelValue, "YYYYWW") : moment();
                this.yearWeek = this.modelValue || moment().year() + "W" + moment().isoWeek();
            },
            date: {
                deep: true,
                handler() {
                    this.$emit("update:modelValue", this.yearWeek);
                }
            },
            yearWeek() {
                this.$emit("update:modelValue", this.yearWeek);
            }
        }
    }
</script>

<style lang="scss" scoped>

.datePicker {
    width: 100%;
    z-index: 9999;
    background: $bgSecondary;
    border-radius: $borderRadius;
}

.daysPicker {
    padding: 10px;
}

.header {
    display: grid;
    grid-template-columns:  minmax(0, 1fr) 40px 40px;
    height: 60px;
    border-bottom: 1px solid $borderColor;
    align-items: center;
    padding: 0 20px;
    .month-name {
        font-weight: 600;
        text-transform: capitalize;
    }
    button {
        border: 0;
        background: transparent;
        padding: 0;
        color: #ccc;
    }
}

.weekPickerDays {
    padding: 20px;
    display: grid;
    row-gap: 5px;
    font-size: 12px;
    .week {
        display: grid;
        grid-template-columns: repeat(8, minmax(0, 1fr));
        column-gap: 5px;
        border-radius: $borderRadius;
        cursor: pointer;
        user-select: none;
        &.active {
            background: $buttonColor2;
        }
        .buttonHolder {
            width: 100%;
            padding-top: 100%;
            position: relative;
            .day {
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: $borderRadius;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                &.subtle {
                    color: #ccc;
                }
                &.subtle2 {
                    color: #ccc;
                    font-size: 10px;
                }
                &.active {
                    background: $buttonColor;
                    color: $buttonText;
                    font-weight: bold;
                }
                &:disabled {
                    background: $buttonText;
                    font-size: 10px;
                }
                &.weekNumber {
                    font-size: 10px;
                    color: $buttonColor;
                }
            }
        }
    }
}
</style>