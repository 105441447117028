import moment from 'moment';

export default {
    install: (app, options) => {
        app.mixin({
            methods: {
                priceConverter(value) {
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                getMidnightDate(val) {
                    let valDate = moment(val).format("YYYY-MM-DD");
                    let date = new Date(`${valDate}T00:00:00.000Z`);
                    return date;
                }
            }
        });

    }
  }