<template>
    <div class="expandableContent">
        <div class="_title" @click="switchShow">
            <h2>{{ title }}</h2>
            <div>
                <span v-if="show"><i class="fas fa-chevron-up"></i></span>
                <span v-else><i class="fas fa-chevron-down"></i></span>
            </div>
        </div>
        <div class="_content" v-show="show">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['title'],
        data() {
            return {
                show: false
            }
        },
        methods: {
            switchShow() {
                this.show = !this.show;
            }
        },
    }
</script>

<style lang="scss" scoped>
.expandableContent {
    display: grid;
    row-gap: 30px;
}

._title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    cursor: pointer;
    user-select: none;
}

._content {
    display: grid;
    row-gap: 20px;
}
</style>