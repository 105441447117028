<template>
    <div class="gridTable">
        <div class="row header">
            <div :key="key" v-for="(col, key) of cols">{{ col }}</div>
        </div>
        <div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['cols']
    }
</script>

<style lang="scss" scoped>
.gridTable {
    box-shadow: $boxShadow;
    border-radius: $borderRadius;
    background: $bgSecondary;
    padding: 20px;
    .row {
        display: grid;
        grid-template-columns: 100px minmax(0, 1fr) 150px 150px minmax(0, 1fr) 100px 120px minmax(0, 1fr) 20px;
        height: 50px;
        align-items: center;
        padding: 10px 20px;
        column-gap: 20px;
        height: 60px;

        &:nth-child(2n) {
            background: $bg;
        }
        &:last-child {
            border-radius: 0 0 $borderRadius $borderRadius;
        }
        &.header {
            font-weight: 500;
            border-radius: $borderRadius;
            background: $buttonColor2;
            height: 50px;
        }
    }
}
</style>