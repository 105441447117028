<template>
    <div class="input">
        <div class="_header">
            <label>{{ name }}</label>
            <span class="_error">{{ error }}</span>
        </div>
        <div class="inputHolder" :class="{ 'withCopyBtn' : copy }">
            <input :type="type" v-model="content" :class="{ 'errorField' : error, 'errorField' : !isValid }" :placeholder="placeholder" @input="$emit('valueChanged')" @focus="$emit('focus')" @blur="$emit('blur'); validateContent()" :disabled="disabled">
            <button v-if="copy" class="default small reverse copyBtn" @click="copyValue" v-tooltip="copyText" :onmouseout="() => { copyText = 'Copy' }">
                <span><i class="fas fa-copy"></i></span>
            </button>
        </div>
    </div>
</template>

<script>
import { parsePhoneNumber, AsYouType, isValidPhoneNumber } from 'libphonenumber-js'

    export default {
        props: ['name', 'value','modelValue', 'type', 'placeholder', 'error', 'disabled', 'copy', 'validate'],
        data() {
            return {
                content: this.modelValue || this.value || '',
                copyText: 'Copy',
                isValid: true
            }
        },
        methods: {
            copyValue() {
                this.copyText = "Copied";
                this.copyTextToClipboard(this.content);
            },
            validateContent() {
                this.isValid = this.isValidInput();
            },
            isValidInput() {
                if(!this.validate) return true;
                if(!this.content) return true;
                if(this.content && this.content.length <= 0) return true;

                // check if phone is valid
                if(this.validate === 'phone') {
                    try {
                        this.content = new AsYouType('US').input(this.content);
                        let isValid = isValidPhoneNumber(this.content, 'US');
                        if(isValid) return true;
                        return false;
                    } catch(e) {
                        return false;
                    }
                }

                if(this.validate === 'double') {
                    var regex = /^(?:[1-9][0-9]*|0)((?:[.]\d{1,2})?)$/;
                    if(!regex.test(this.content)) return false;
                    return true;
                }

            }
        },
        computed: {
            
        },
        watch: {
            content() {
                this.$emit('update:modelValue', this.content);
                this.$emit('change', this.content);
            },
            modelValue() {
                this.content = this.modelValue || this.value || ''
            }
        },
    }
</script>

<style lang="scss">
.inputHolder {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    column-gap: 10px; 
    &.withCopyBtn {
        grid-template-columns: minmax(0, 1fr) auto;
        .copyBtn {
            height: 100%;
            position: relative;
        }
    }
}

.errorField {
    border: 1px solid red !important;
}
</style>