import { mapGetters } from "vuex";

export default {
    data() {
        return {
            notificationsCenter: {
                show: false,
                notifications: []
            }
        }
    },
    computed: {
        ...mapGetters(['webSocket'])
    },
    watch: {
        webSocket() {
            this.websocketListener();
        }
    },
    mounted() {
        this.getUserNotifications();
        this.websocketListener();
    },
    methods: {
        websocketListener() {
            if(!this.webSocket) return;
            try {
                this.webSocket.on('NotificationMessage', (data) => {
                    this.notificationsCenter.notifications.unshift(data);
                    try {
                        var audio = new Audio('/sounds/notification.mp3');
                        audio.play();
                    } catch(e) {

                    }
                });
            } catch(e) {
                
            }
        },
        getUserNotifications() {
            this.ajax('GetUserNotifications', {
                url: '/myNotifications',
                method: 'GET'
            }, (err, body) => {
                if(!err) this.notificationsCenter.notifications = body;
            });
        },
        switchNotificationsCenterOnOff() {
            this.notificationsCenter.show = !this.notificationsCenter.show;
        },
        hideNotificationsCenter() {
            this.notificationsCenter.show = false;
        },
        viewAllNotifications() {
            this.notificationsCenter.notifications = [];
            this.ajax('MarkAllNotificationsAsSeen', {
                url: '/myNotifications/MarkAllAsSeen',
                method: 'POST'
            }, (err, body) => { });
        },
        markNotificationAsSeen(id, key) {
            this.notificationsCenter.notifications.splice(key, 1);
            this.ajax('MarkOneNotificationAsSeen', {
                url: '/myNotifications/MarkOneAsSeen/' + id,
                method: 'POST'
            }, (err, body) => { });
        }
    }
}