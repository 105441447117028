<template>
    <div class="pageHeader">
        <h2 class="_title">{{ title }}</h2>

        <div class="actionGroup">
            <div class="actionBar">
                <slot></slot>
            </div>

            <UserMenuHeader/>

        </div>
        
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserMenuHeader from './UserMenuHeader.vue'

    export default {
        props: ['title'],
        components: {
            UserMenuHeader
        },
        methods: {
            
        },
        computed: {
            ...mapGetters(['user'])
        }
    }
</script>

<style lang="scss" scoped>
.pageHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 0 20px 0 120px;
    background: $bg;
    h2._title {
        font-weight: 400;
        font-size: 24px;
    }
}

.actionGroup {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.navMenuBtn {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 0;
    background: $bgSecondary;
    box-shadow: $boxShadow;
    font-size: 18px;
}
</style>