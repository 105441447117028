<template>
    <div class="listPicker" :class="{ 'active' : showOptions }" v-click-outside="hideOptions">
        <a href="" class="selectedOption" :style="options[selectedOpt].style" @click.prevent="switchOptions">
            <span>{{ options[selectedOpt].text }}</span><span v-if="!disabled"><i class="fas fa-caret-down"></i></span>
        </a>
        <div class="menu" v-if="showOptions && !disabled">
            <a href="" :key="key" v-for="(item, key) in options" :class="{ 'active' : key == selectedOpt }" @click.prevent="select(item, key)">
                <span class="text">{{ item.text }}</span>
                <span class="check" v-if="key == selectedOpt"><i class="fas fa-check"></i></span>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['options', 'selected', 'disabled'],
        data() {
            return {
                showOptions: false,
                selectedOpt: this.selected || 0
            }
        },
        methods: {
            switchOptions() {
                if(this.disabled) return;
                this.showOptions = !this.showOptions;
            },
            hideOptions() {
                this.showOptions = false;
            },
            select(item, key) {
                this.selectedOpt = key;
                this.hideOptions();
                this.$emit("select", key);
            }
        }
    }
</script>

<style lang="scss" scoped>
.listPicker {
    position: relative;
    z-index: 5;
    width: 100%;
    height: 100%;
    &.active {
        z-index: 6;
    }
    button {
        width: 20px;
        height: 20px;
        border: 0;
        background: transparent;
    }
    .menu {
        position: absolute;
        top: 0;
        right: 0;
        background: $bgSecondary;
        border: 1px solid $borderColor;
        border-radius: $borderRadius;
        width: 100%;
        min-height: 40px;
        box-shadow: $boxShadow;
        overflow: hidden;
        a {
            display: flex;
            height: 40px;
            align-items: center;
            justify-content: space-between;
            padding: 0 10px;
            .check {
                font-size: 12px;
            }
            &:hover {
                background: $bg;
            }
            &.remove {
                color: $error;
            }
        }
    }
}

.selectedOption {
    display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    font-size: 13px;
    color: $text;
}
</style>