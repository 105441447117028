import { mapGetters } from "vuex";

export default {
    data() {
        return {
            quickMessageData: {
                modal: false,
                body: {
                    to: null,
                    message: ''
                }
            }
        }
    },
    methods: {
        sendQuickMessage() {
            this.$axios.post('/chats/newMessage', this.quickMessageData.body)
            .then(data => {

                this.$notify({
                    type: 'info',
                    text: 'Message has been sent'
                });

                this.quickMessageData.modal = false;
                this.quickMessageData = {
                        modal: false,
                        body: {
                            to: null,
                            message: ''
                        }
                    }
            })
            .catch(err => {
                this.$notify({
                    type: 'danger',
                    text: 'Something went wrong. Please, try again!'
                });
            });
        }
    },
    computed: {
        ...mapGetters(['quickMessage'])
    },
    watch: {
        quickMessage: {
            deep: true,
            handler() {
                this.quickMessageData = this.quickMessage;
            }
        },
        quickMessageData: {
            deep: true,
            handler() {
                this.$store.commit('quickMessage', this.quickMessageData);
            }
        }
    }
}