<template>
    <div class="notificationsCenter">
        <div class="content-scrollable">
            <div class="title">
                <div class="name">Notifications</div>
                <div class="action">
                    <button class="default small round reverse" @click="$emit('viewAll');" v-if="notifications.length > 0">View all</button>
                </div>
            </div>

            <div class="notificationsList" v-if="notifications.length > 0">
                <NotificationItem 
                    :key="key" 
                    v-for="(notification, key) of notifications" 
                    :item="notification"
                    @openLoadChat="openLoadChat"
                    @viewOne="(id) => { $emit('viewOne', id, key) }"
                    />
            </div>

            <div class="noNotificationsMessage" v-else>
                <span class="icon"><i class="fa-regular fa-bell-slash"></i></span>
                <span>You're all caught up.</span>
            </div>
            
        </div>
    </div>
</template>

<script>
import NotificationItem from './NotificationItem.vue'
import LoadChat from '../LoadsChat/Chat.vue'

    export default {
        components: {
            NotificationItem
        },
        props: ['notifications'],
        methods: {
            openLoadChat(loadId) {
                this.$showModalSimple(LoadChat, 'Chat', {
                    loadId: loadId
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
.notificationsCenter {
    height: 100%;
}

.content-scrollable {
    overflow-y: auto;
    height: 100%;
}



.title {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid $borderColor;
    position: sticky;
    top: 0;
    background: $bgSecondary;
    .name {
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
    }
}

.noNotificationsMessage {
    height: calc(100% - 50px);
    display: grid;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
    row-gap: 20px;
    .icon {
        font-size: 40px;
        opacity: 0.85;
    }
}
</style>