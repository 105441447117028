<template>
    <div>
        <notifications class="notificationCenter" />
        <router-view></router-view>
        <ModalCaller/>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                darkModeOn: true
            }
        },
        methods: {
            
        },
        
    }
</script>

<style lang="scss">
// css
.notificationCenter {
    top: 20px !important;
    right: 20px !important;
    z-index: 999999999 !important;
}
</style>