<template>
    <div class="modalGroup" :class="{ 'pointerEventsOn' : show }">
        <div class="modalBG" :class="{ 'visibleAnimation' : show }"  @click="hide"></div>
        <div class="_scrollable" :class="{ 'overflowScroll' : show }">

            <div class="scrollableContentHolder">
                <div class="_container"  :class="`${ ( show ? 'visibleAnimation transformBack' : '') } ${customSize}`">
                    <div class="loaderHolder" v-if="loading"><PageLoader/></div>
                    <div class="_title">
                        <div class="text">{{ title }}</div>
                        <button class="closeBtn" @click="hide"><i class="fas fa-times"></i></button>
                    </div>
                    <div class="_content" :class="{ 'disablePadding' : disablePadding }"><slot></slot></div>
                    <div class="_action" v-if="!hideButtons">
                        <button class="default medium reverse" @click="hide">{{ closeBtnText }}</button>
                        <button class="default medium" @click="$emit('confirm');">{{ confirm }}</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageLoader from './PageLoader.vue'
    export default {
        components: {
            PageLoader
        },
        props: ['title', 'confirm', 'modelValue', 'closeText', 'size', 'hideButtons', 'loading', 'disablePadding'],
        data() {
            return {
                show: this.modelValue || false,
                closeBtnText: this.closeText || 'Cancel',
                customSize: this.size || ''
            }
        },
        computed: {
            ...mapGetters(['numberOfModals'])
        },
        methods: {
            hide() {
                this.show = false;
                this.$emit('close');
            }
        },
        watch: {
            show() {
                this.$emit('update:modelValue', this.show);
                if(this.show) {
                    this.$store.commit('numberOfModals', this.numberOfModals + 1);
                } else {
                    this.$store.commit('numberOfModals', this.numberOfModals - 1);
                }
            },
            modelValue() {
                this.show = this.modelValue;
            }
        }
    }
</script>

<style lang="scss" scoped>
.modalGroup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    pointer-events: none;
}

.modalBG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    opacity: 0;
    transition: ease 0.5s;
}

._scrollable {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    &.overflowScroll {
        overflow-y: auto;
    }
}

.scrollableContentHolder {
    width: 100%;
    min-height: 100vh;
    padding: 100px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

._container {
    max-width: 450px;
    width: 100%;
    background: $bg;
    border-radius: $borderRadius;
    display: grid;
    row-gap: 20px;
    opacity: 0;
    transition: ease 0.5s;
    transform: translateY(-50px);
    position: relative;
    &.large {
        max-width: 750px;
    }
    .loaderHolder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255,0.3);
        z-index: 2;
        border-radius: $borderRadius;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

._action {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
    padding: 0 30px 30px 30px;
}

._title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 30px 0 30px;
    .text {
        font-weight: 600;
        font-size: $md-text;
        text-transform: uppercase;
    }
    .closeBtn {
        border: 0;
        background: transparent;
        color: $error;
        padding: 0;
        height: 34px;
        width: 34px;
    }
}

._content {
    padding: 0 30px 30px 30px;
    &.disablePadding {
        padding: 0 0 30px 0;
    }
}
</style>