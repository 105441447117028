import { shallowRef } from "vue";
import { createVNode, defineComponent, render } from "vue/dist/vue.esm-bundler";

import Modal from './components/Modal'

export default {
    install: (app, options) => {
        
        let component = {
            components: {
                Modal
            },
            data() {
                return {
                    userComponent: null,
                    title: 'My Custom Component',
                    shown: false,
                    options: {},
                    callback: null
                }
            },
            methods: {
                onClose(data = {}) {
                    this.userComponent = null;
                    this.shown = false;
                    if(this.callback) this.callback(data);
                }
            },
            mounted() {
                app.config.globalProperties.$showModalSimple = (userComponent, title, options = {}, callback) => {
                    this.shown = true;
                    this.title = title;
                    this.userComponent = shallowRef(userComponent);
                    this.options = JSON.parse(JSON.stringify(options));
                    this.callback = callback;
                }

                app.config.globalProperties.$hideModalSimple = (data) => {
                   this.onClose(data);
                }
            },
            template: `<Modal v-model="shown" :title="title" @close="onClose"><component :is="userComponent" :options="options" @close="onClose"/></Modal>`
        }

        app.component('ModalCaller', component);
        
    }
  }