<template>
    <div class="notification">



        <div class="text" v-if="item.type == 'NEW_MESSAGE'" @click="$emit('openLoadChat', item.loadId)">
            <span v-if="item.fromDriver">You have a new message from driver <strong v-if="item.driver"><a href="" @click.prevent>{{ item.driver.name }}</a></strong>.</span>
            <span v-else>You have a new message.</span>
        </div>
        <div class="text" v-else>Different notification</div>



        <div class="btn"><button class="default small reverse" @click="$emit('viewOne', item._id)"><i class="fa-solid fa-xmark"></i></button></div>
    </div>
</template>

<script>
    export default {
        props: ['item']
    }
</script>

<style lang="scss" scoped>
.notification {
    padding: 10px 20px;
    border-bottom: 1px solid $borderColor;
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    column-gap: 20px;
    align-items: center;
    align-content: center;
    cursor: pointer;
    user-select: none;
    &:hover {
        background: rgba(0,0,0,0.01);
    }
    .text {
        font-size: 12px;
    }
    &:last-child {
        border: 0;
    }
    .btn {
        button {
            padding: 0;
            width: 34px;
            height: 34px;
            border-radius: 10px;
            font-size: 11px;
        }
    }
}
</style>