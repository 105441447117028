<template>
    <div class="autoCompleteGroup" :class="{ 'active' : dropDownShown }" v-click-outside="hideDropDown">
        <input type="text" :disabled="disabled" :placeholder="placeholder" :class="inputClass" v-model.trim="input" @focus="dropDownShown = true" 
            @keydown.enter.prevent="selectedItemWithOffset" @keydown="nextItem"
            >
        <div class="dropDownGroup" v-show="dropDownShown && results.length > 0">
            <div class="_scrollableContent" ref="scrollableContent">
                <div class="listGroup">
                    <button class="textNoWrap" @click.stop="selectItem(item)" :class="{ 'active' : selectionOffset == key }" :key="key" v-for="(item, key) of results">{{ item }}</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        props: ['modelValue', 'type', 'inputClass', 'placeholder', 'disabled'],
        data() {
            return {
                input: this.modelValue,
                dropDownShown: false,
                typeOfSuggestions: this.type || 'default',
                results: [],
                selectionOffset: 0
            }
        },
        methods: {
            scrollToOption(el) {
                var listElement = this.$refs['scrollableContent'];

                let offsetTop = el.offsetTop;
                let elHeight = el.offsetHeight;
                let listElementHeight = listElement.offsetHeight;

                let topOffset = offsetTop + (elHeight/2) - (listElementHeight / 2);

                listElement.scroll({ top: topOffset });

            },
            nextItem(event) {                
                if (event.keyCode == 38 && this.selectionOffset > 0) {
                    this.selectionOffset--;
                    event.preventDefault();
                } else if (event.keyCode == 40 && this.selectionOffset < this.results.length - 1) {
                    this.selectionOffset++;
                    event.preventDefault();
                }

                let element = document.querySelectorAll('.listGroup button')[this.selectionOffset];
                if(!element) return;
                this.scrollToOption(element);

            },
            selectedItemWithOffset(e) {
                e.target.blur();
                if(!this.results[this.selectionOffset]) return;
                this.selectItem(this.results[this.selectionOffset]);
            },
            getSuggestions() {
                var listElement = this.$refs['scrollableContent'];
                listElement.scroll({ top: 0 });
                this.results = [];
                if(!this.input) {
                    this.results = [];
                    return;
                }
                this.$axios.get(`/autocomplete/${this.typeOfSuggestions}/${this.input}`)
                .then(data => {
                    this.results = data.data;
                })
            },
            selectItem(item) {
                this.input = item;
                this.hideDropDown();
            },
            hideDropDown() {
                this.dropDownShown = false;
            }
        },
        watch: {
            modelValue() {
                this.input = this.modelValue;
            },
            input() {
                this.results = [];
                this.$emit('update:modelValue', this.input);
                this.getSuggestions();
                this.selectionOffset = 0;
            }
        }
    }
</script>

<style lang="scss" scoped>
.autoCompleteGroup {
    position: relative;
    z-index: 99;
    width: 100%;
    &.active {
        z-index: 100;
    }
    input {
        width: 100%;
    }
    .dropDownGroup {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        transform: translateY(20px);
        background: $bgSecondary;
        box-shadow: $boxShadow;
        border-radius: $borderRadius;
        overflow: hidden;
        border: 1px solid $borderColor;
        min-width: 150px;
        max-width: 250px;
        ._scrollableContent {
            max-height: 200px;
            overflow-y: auto;
            .listGroup {
                display: grid;
                button {
                    background: transparent;
                    text-align: left;
                    border: 0;
                    height: 30px;
                    padding: 0 10px;
                    &:hover, &.active {
                        background: $buttonColor;
                        color: #fff;
                    }
                }
            }          
        }
    }
}
</style>