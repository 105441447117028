import { mapGetters } from "vuex";

export default {
    data() {
        return {
            cancelToken: {}
        }
    },
    computed: {
        ...mapGetters(['axiosLoaders'])
    },
    methods: {
        isLoading(list) {
            return this.axiosLoaders.some(r => list.includes(r));
        },
        async ajax(name, options, callback) {

            if(this.cancelToken[name]) this.cancelToken[name].cancel();
            this.cancelToken[name] = this.$axios.CancelToken.source();

            this.$store.state.axiosLoaders.push(name);

            let { url, method, data } = options;

            await this.$axios({
                method: method,
                url: url,
                data,
                cancelToken: this.cancelToken[name].token
            })
            .then(data => {
                callback(false, data.data);
            })
            .catch(err => {
                if (!this.$axios.isCancel(err)) {
                    let response = err.response;
                    if(response && response.data) {
                        let body = response.data;
                        callback(true, body);
                    } else {
                        callback(true, {});
                    }
                }
            });

            let axiosLoaders = this.$store.state.axiosLoaders;
            axiosLoaders.splice(axiosLoaders.indexOf(name), 1);

        }
    }
}