<template>
    <div class="userProfileMenu">

        <div class="profileBtns" v-click-outside="hideNotificationsCenter">
            <div class="unreadChats" v-if="notificationsCenter.notifications.length > 0"></div>
            <button class="chatBtn" @click="switchNotificationsCenterOnOff"><i class="fa-solid fa-bell"></i></button>
            <div class="notificationCenterGroup" :class="{ 'active' : notificationsCenter.show }">
                <NotificationsHolder :notifications="notificationsCenter.notifications" @viewAll="viewAllNotifications" @viewOne="markNotificationAsSeen"/>
            </div>
        </div>
        

        <div class="profileBtns">
            <div class="unreadChats" v-if="chatsUnread > 0"></div>
            <button class="chatBtn" @click="switchChat"><i class="fas fa-comments"></i></button>
        </div>
        

        <div class="userProfileGroup">
            <div class="userProfile" @click="redirectToProfile">
                <div class="profilePicture" :style="`background-image:url('${user.profileImage}')`"></div>

                <div class="content">
                    <div class="name textNoWrap">{{ user.name }}</div>
                    <div class="position textNoWrap">{{ user.position }}</div>
                </div>
            </div>

            <div class="dropDownLogOut">
                <a href="" @click.prevent="redirectToProfile">
                    <div class="icon"><i class="fas fa-user"></i></div>
                    <span>Profile</span>
                </a>
                <a href="" @click.prevent="logOut">
                    <div class="icon"><i class="fas fa-sign-out-alt"></i></div>
                    <span>Log out</span>
                </a>
            </div>

        </div>



    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NotificationsCenter from '../mixins/NotificationsCenter'
import NotificationsHolder from './NotificationsCenter/NotificationsHolder.vue'

    export default {
        mixins: [NotificationsCenter],
        components: {
            NotificationsHolder
        },
        computed: {
            ...mapGetters(['user', 'chat', 'chatsUnread']),
            apiURL() {
                return process.env.VUE_APP_API_URL;
            },
            image() {
                if(!this.user.image || !this.user.image.path) return '';
                return this.apiURL + this.user.image.path;
            }
        },
        methods: {
            switchChat() {
                this.$store.commit('chat', !this.chat);
            },
            redirectToProfile() {
                this.$router.push({ path: `/app/profile/${this.user.username}` });
            },
            logOut() {
                localStorage.removeItem("token");
                this.$store.commit('token', null);
                this.$store.commit('user', null);
                this.$router.push({ path: '/' });
            }
        },
        
    }
</script>

<style lang="scss" scoped>

.userProfileMenu {
    display: grid;
    grid-template-columns: 50px 50px auto;
    column-gap: 20px;
    align-items: center;
    align-content: center;
    border-left: 1px solid $borderColor;
    padding: 0 0 0 20px;
}

.userProfileGroup {
    display: grid;
    row-gap: 20px;
    cursor: pointer;
    position: relative;
    padding: 10px;
    &:hover {
        .dropDownLogOut {
            opacity: 1;
            pointer-events: auto;
        }
    }
}

.dropDownLogOut {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    opacity: 0;
    background: $bgSecondary;
    box-shadow: $boxShadow;
    padding: 100px 10px 10px 10px;
    pointer-events: none;
    transition: ease 0.3s;
    border-radius: $borderRadius;
    a {
        display: grid;
        align-items: center;
        grid-template-columns: 40px auto;
        border-radius: $borderRadius;
        color: #909090;
        transition: ease 0.5s;
        .icon {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        span {
            overflow: hidden;
            transition: ease 0.5s;
            padding: 0 20px;
        }

        &:hover, &.router-link-exact-active {
            color: $buttonColor;
            background: $bg;
            box-shadow: $boxShadow;
        }
    }
}



.userProfile {
    position: relative;
    display: grid;
    grid-template-columns: 60px minmax(0, 1fr);
    align-items: center;
    column-gap: 20px;
    z-index: 2;
    width: 250px;
    .content {
        display: grid;
        row-gap: 5px;
        cursor: pointer;
        user-select: none;
    }
    .name {
        font-weight: 500;
        color: $text;
        font-size: 18px;
    }
}


.profilePicture {
    width: 60px;
    height: 60px;
    background-color: #fff;
    background-image: url('https://images.unsplash.com/flagged/photo-1595514191830-3e96a518989b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80');
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    border: 3px solid $buttonColor;
}

.profileBtns {
    width: 50px;
    height: 50px;
    position: relative;
    button {
        width: 100%;
        height: 100%;
        background: transparent;
        border: 0;
        font-size: 20px;
    }
}


.unreadChats {
    position: absolute;
    top: 0;
    right: 0;
    background: $error;
    color: #fff;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
}

.notificationCenterGroup {
    position: absolute;
    top: 100%;
    right: 0;
    transform: translateY(50px);
    width: 450px;
    height: 300px;
    background: $bgSecondary;
    z-index: 999;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    box-shadow: $boxShadow;
    opacity: 0;
    pointer-events: none;
    transition: ease 0.5s;
    overflow: hidden;
    &.active {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(20px);
    }
}

</style>