import { mapGetters } from "vuex";

export default {
    data() {
        return {
            viewLoadData: {
                modal: false,
                load: null
            }
        }
    },
    methods: {
        
    },
    computed: {
        ...mapGetters(['viewLoadStore'])
    },
    watch: {
        viewLoadStore: {
            deep: true,
            handler() {
                this.viewLoadData = this.viewLoadStore;
            }
        },
        viewLoadData: {
            deep: true,
            handler() {
                this.$store.commit('viewLoadStore', this.viewLoadData);
            }
        }
    }
}