// import store from '../store'

const isPermitted = (access, permission, action) => {
    if(!access) return false;
    if(!access[permission]) return false;
    if(access[permission].includes(action)) return true;
    return false;
}

module.exports = {
    isPermitted
}