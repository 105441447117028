import {
    createRouter,
    createWebHistory
} from 'vue-router'

import axios from 'axios';
import store from '../store';
import permissions from '../helpers/permissionCheck'

const routes = [];



// login route
routes.push({ path: '/', name: 'LoginHome', component: function () { return import('../views/Login.vue') } });

// carrier's invoice page
routes.push({ path: '/invoice/:id', name: 'InvoicePage', component: function () { return import('../views/invoices/InvoicePage.vue') } });

// user invitation page
routes.push({ path: '/invitation/:id', name: 'InvitationPage', component: function () { return import('../views/users/Invitation.vue') } });
// password restore
routes.push({ path: '/restore', name: 'RestorePage', component: function () { return import('../views/users/Restore.vue') } });
routes.push({ path: '/restore/:id', name: 'ResetPasswordPage', component: function () { return import('../views/users/ResetPassword.vue') } });

// loads by carrier
routes.push({ path: '/loads/:id', name: 'CarrierLoads', component: () => { return import('../views/carriers/Loads.vue') } });

// main app route
let mainApp = { path: '/app', name: 'App', component: function () { return import('../views/App.vue') }, meta: { requiresAuth: true }, children: [] };

// main app children routes
mainApp.children.push({ path: '', name: 'Dashboard', component: () => { return import('../views/Dashboard.vue') } });

// profile pages
mainApp.children.push({ path: 'profile/:username', name: 'ProfilePage', component: () => { return import('../views/profile/Profile.vue') } });

// dispatcher pages
mainApp.children.push({ path: 'dispatch', name: 'LoadsHome', component: () => { return import('../views/dispatch/Main.vue') }, meta: { access: { name: 'loads', action: 'view' } } });

mainApp.children.push({ path: 'search', name: 'LoadsSearch', component: () => { return import('../views/dispatch/Search.vue') }, meta: { access: { name: 'loads', action: 'view' } } });

// users pages
mainApp.children.push({ path: 'users', name: 'UsersHome', component: () => { return import('../views/users/List.vue') }, meta: { access: { name: 'users', action: 'view' } } });

// carriers pages
mainApp.children.push({ path: 'carriers', name: 'CarriersHome', component: () => { return import('../views/carriers/List.vue') }, meta: { access: { name: 'carriers', action: 'view' } } });

// teams pages
mainApp.children.push({ path: 'teams', name: 'TeamsHome', component: () => { return import('../views/teams/List.vue') }, meta: { access: { name: 'teams', action: 'manage' } } });

// report pages
mainApp.children.push({ path: 'reports', name: 'ReportsHome', component: () => { return import('../views/reports/Main.vue') }, meta: { access: { name: 'loads', action: 'view' } } });

// customers pages
mainApp.children.push({ path: 'customers', name: 'CustomersHome', component: () => { return import('../views/Customers.vue') }, meta: { access: { name: 'loads', action: 'view' } } });

// invoices pages
mainApp.children.push({ path: 'invoices', name: 'InvoicesHome', component: () => { return import('../views/invoices/Main.vue') }, meta: { access: { name: 'invoices', action: 'manage' } } });

// gross income by week page
mainApp.children.push({ path: 'perfomance', name: 'PerfomanceHome', component: () => { return import('../views/perfomance/Main.vue') }, meta: { access: { name: 'invoices', action: 'manage' } } });

// expenses
mainApp.children.push({ path: 'expenses', name: 'Expenses', component: () => { return import('../views/expenses/Main.vue') }, meta: { access: { name: 'invoices', action: 'manage' } } });


// chat
mainApp.children.push({ path: 'chats', name: 'chats', component: () => { return import('../views/Chat.vue') } });


// tracking app
mainApp.children.push({ path: 'tracking', name: 'TrackingManager', component: () => { return import('../views/Tracking/MyTracking.vue') }, meta: { requiresAuth: true, access: { name: 'tracking', action: 'create' } } });
routes.push({ path: '/tracking/create', name: 'TrackingCreate', component: () => { return import('../views/Tracking/Create.vue') }, meta: { requiresAuth: true, access: { name: 'tracking', action: 'create' } } });
routes.push({ path: '/tracking/edit/:id', name: 'TrackingEdit', component: () => { return import('../views/Tracking/Create.vue') }, meta: { requiresAuth: true, access: { name: 'tracking', action: 'create' } } });

routes.push({ path: '/tracking/track/:id', name: 'TrackingTrack', component: function () { return import('../views/Tracking/Track.vue') } });

routes.push(mainApp);



const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});


router.beforeEach(async (to, from, next) => {

    store.state.numberOfModals = 0;

    let token = localStorage.getItem('token');
    if(to.meta && to.meta.requiresAuth) {

        if(!token) return next({ path: '/' });
        store.commit('token', token);

        await axios.get('/user')
        .then(data => {
            if(data.data.user) {
                store.commit('user', data.data.user.user);
            } else {
                store.commit('token', null);
                store.commit('user', null);
            }
        })
        .catch(err => {
            store.commit('token', null);
            
        });

        if(!store.state.user) {
            localStorage.removeItem('token');
            return next({ path: '/' });
        }

        // permissions check

        if(to.meta && to.meta.access) {
            let permission = to.meta.access.name;
            let action = to.meta.access.action;
            let isPermitted = permissions.isPermitted(store.state.user.permissions, permission, action);
            if(!isPermitted) return next({ path: '/app' });
        }

    }

    return next();
});

  

export default router