import { createStore } from 'vuex'

export default createStore({
    state: {
        token: null,
        user: null,
        carriers: [],
        users: [],
        webSocket: null,
        positions: ['Operation Manager', 'Dispatch', 'CEO', 'COO', 'Supervisor', 'After hours'],
        loadStatus: {
            pending: { text: 'Pending', style: "background: transparent" },
            pickedup: { text: 'Picked up', style: "background: #007aff; color: #fff;" },
            delayed: { text: 'Delayed', style: "background: #F7DC6F; color: #000000;" },
            canceled: { text: 'Canceled', style: "background: #E74C3C; color: #fff;" },
            delivered: { text: 'Delivered', style: "background: #28B463; color: #fff;" }
        },
        navigation: false,
        chat: false,
        chatsUnread: 0,
        duplicateLoad: null,
        quickMessage: {
            modal: false,
            body: {
                to: null,
                message: ''
            }
        },
        viewLoadStore: {
            modal: false,
            load: null
        },
        numberOfModals: 0,
        axiosLoaders: [],
        loadManager: {
            addLoad: false,
            addLoadDetails: {},
            viewLoad: null
        }
    },
    getters: {
        token: (state) => {
            return state.token;
        },
        user: (state) => {
            return state.user;
        },
        carriers: (state) => {
            return state.carriers;
        },
        users: (state) => {
            return state.users;
        },
        webSocket: (state) => {
            return state.webSocket;
        },
        positions: (state) => {
            return state.positions;
        },
        loadStatus: (state) => {
            return state.loadStatus;
        },
        navigation: (state) => {
            return state.navigation;
        },
        chat: (state) => {
            return state.chat;
        },
        chatsUnread: (state) => {
            return state.chatsUnread;
        },
        quickMessage: (state) => {
            return state.quickMessage;
        },
        viewLoadStore: (state) => {
            return state.viewLoadStore;
        },
        numberOfModals: (state) => {
            return state.numberOfModals
        },
        duplicateLoad: (state) => {
            return state.duplicateLoad
        },
        axiosLoaders: (state) => {
            return state.axiosLoaders
        },
        loadManager: (state) => {
            return state.loadManager;
        }
    },
    mutations: {
        token: (state, payload) => {
            state.token = payload;
        },
        user: (state, payload) => {
            state.user = payload;
        },
        carriers: (state, payload) => {
            state.carriers = payload;
        },
        users: (state, payload) => {
            state.users = payload;
        },
        webSocket: (state, payload) => {
            state.webSocket = payload;
        },
        navigation: (state, payload) => {
            state.navigation = payload;
        },
        chat: (state, payload) => {
            state.chat = payload;
        },
        chatsUnread: (state, payload) => {
            state.chatsUnread = payload;
        },
        quickMessage: (state, payload) => {
            state.quickMessage = payload;
        },
        viewLoadStore: (state, payload) => {
            state.viewLoadStore = payload;
        },
        numberOfModals: (state, payload) => {
            state.numberOfModals = payload;
        },
        duplicateLoad: (state, payload) => {
            state.duplicateLoad = payload;
        }
    }
})


