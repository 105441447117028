<template>
        <button class="switchButton" @click="switchCheck" :class="{ 'active' : switched }">
            <div class="_circle"></div>
        </button>
</template>

<script>
    export default {
        props: ['modelValue', 'value'],
        data() {
            return {
                switched: this.modelValue || this.value || false
            }
        },
        methods: {
            switchCheck() {
                this.switched = !this.switched;
            }
        },
        mounted() {
            this.$emit('update:modelValue', this.switched);
        },
        watch: {
            modelValue() {
                this.switched = this.modelValue;
            },
            switched() {
                this.$emit('update:modelValue', this.switched);
                this.$emit('change', this.switched);
            }
        }
    }
</script>

<style lang="scss" scoped>
.switchButton {
    width: 54px;
    height: 30px;
    border: 0;
    border-radius: 15px;
    background: $bg;
    transition: ease 0.3s;
    position: relative;
    box-shadow: inset 0px 0px 3px 2px rgba(0, 0, 0, 0.05);
    ._circle {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 30px;
        width: 30px;
        background: transparent;
        border-radius: 50%;
        transition: ease 0.3s;
        border: 1px solid #eee;
        background: #fff;
        box-shadow: 2px 1px 4px 2px rgba(0, 0, 0, 0.05);
    }
    &.active {
        background: $success;
        ._circle {
            left: 24px;
            transition: ease 0.3s;
            background: #fff;
        }
    }
}
</style>