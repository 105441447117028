import { mapGetters } from "vuex";
import permissions from '../helpers/permissionCheck'

export default {
    computed: {
        ...mapGetters(['user']),
    },
    methods: {
        isUserPermitted(permission, action) {
            return permissions.isPermitted(this.user.permissions, permission, action);
        }
    }
}