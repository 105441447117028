import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'animate.css';

import Input from './components/Input';
import PageHeader from './components/PageHeader';
import SimpleDropDown from './components/SimpleDropDown';
import ObjDropDown from './components/ObjDropDown';
import Modal from './components/Modal';
import ModalSimple from './components/ModalSimple';
import Datepicker from 'vue3-datepicker'
import DatePicker2 from './components/DatePicke';
import Table from './components/Table';
import CheckSwitch from './components/CheckSwitch';
import Expandable from './components/Expandable';
import ListPicker from './components/ListPicker.vue'
import AutoComplete from './components/AutoComplete.vue'
import PageLoader from './components/PageLoader.vue'
import WeekPickerCalendar from './components/WeekPickerCalendar.vue'


import vClickOutside from "click-outside-vue3"

import tooltip from "./directives/tooltip.js";
import "@/assets/scss/tooltip.css";

import './axios';
import axios from 'axios';

import PriceConverter from './plugins/PriceConverter'

import permissionsCheck from './mixins/permissionCheck'
import Sounds from './mixins/Sounds'
import Notifications from '@kyvg/vue3-notification'
import QuickLoadShare from './mixins/QuickLoadShare';
import ViewLoad from './mixins/ViewLoad';
import copyToClipboard from './mixins/copyToClipboard';


// Font Awesome
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, far, fab)
dom.watch();


let app = createApp(App);
app.config.globalProperties.$axios = axios;

app.component("Input", Input);
app.component("PageHeader", PageHeader);
app.component("SimpleDropDown", SimpleDropDown);
app.component("ObjDropDown", ObjDropDown);
app.component("Modal", Modal);
app.component("ModalSimple", ModalSimple);
app.component("Datepicker", Datepicker);
app.component("DatePicker2", DatePicker2);
app.component("Table", Table);
app.component("CheckSwitch", CheckSwitch);
app.component("Expandable", Expandable);
app.component("ListPicker", ListPicker);
app.component("AutoComplete", AutoComplete);
app.component("PageLoader", PageLoader);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("WeekPickerCalendar", WeekPickerCalendar);

app.mixin(permissionsCheck);
app.mixin(Sounds);
app.mixin(QuickLoadShare);
app.mixin(ViewLoad);
app.mixin(copyToClipboard);

import axiosLoader from './mixins/axiosLoader'
app.mixin(axiosLoader);

app.use(vClickOutside);
app.use(PriceConverter);
app.use(Notifications)

import CustomFunctions from './plugins/CustomFunctions'
app.use(CustomFunctions);

app.directive("tooltip", tooltip);

import ModalCaller from './plugins/ModalCaller'
app.use(ModalCaller);

app.use(store);
app.use(router);
app.mount('#app');
