<template>
    <div class="simpleDropDown" v-click-outside="hideShow">
        <button class="selected" @click="switchShow">{{ selectedOption }} <i class="fas fa-caret-down"></i></button>
        <div class="dropdown" v-if="show">
            <button :key="key" v-for="(opt, key) in options" :class="{ 'active' : opt == selectedOption }" @click="select(opt)">
                <span>{{ opt }}</span>
                <span class="check" v-if="opt == selectedOption"><i class="fas fa-check"></i></span>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['options', 'modelValue'],
        data() {
            return {
                selectedOption: this.modelValue || '',
                show: false
            }
        },
        methods: {
            switchShow() {
                this.show = !this.show;
            },
            hideShow() {
                this.show = false;
            },
            select(opt) {
                this.selectedOption = opt;
                this.hideShow();
            }
        },
        watch: {
            selectedOption() {
                this.$emit('update:modelValue', this.selectedOption);
            }
        }
    }
</script>

<style lang="scss" scoped>
.simpleDropDown {
    position: relative;
    border-radius: $borderRadius;
    background: $bgSecondary;
    z-index: 10;
    padding: 0;
    min-width: 120px;
    button {
        width: 100%;
        height: 40px;
        border: 0;
        background: transparent;
        color: $text;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;
        padding: 0 20px;
    }
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    transform: translateY(20px);
    width: 100%; 
    background: $bgSecondary;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    width: 100%;
    box-shadow: $boxShadow;
    overflow: hidden;
    button {
        border-radius: 3px;
        height: 40px;
        padding: 0 20px;
        &.active {
            font-weight: bold;
            color: $buttonColor;
        }
        &:hover {
            background: $buttonColor2;
        }
    }
}


</style>