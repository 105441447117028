<template>
    <div class="modalGroup" :class="{ 'pointerEventsOn' : shown }">
        <div class="modalGroupBg" :class="{ 'visibleAnimation' : shown }" @click="closeModal"></div>
        <div class="modalGroupScroller" ref="modalContainer" :class="{ 'transformBack' : shown }">
            
            <div class="modalContainer">
                <div class="loaderHolder" v-if="loading"><PageLoader/></div>
                <div class="modalTitle" v-if="!hideTitle">
                    <h1>{{ title }}</h1>
                    <button class="default small closeBtn" @click="closeModal"><i class="fas fa-times"></i></button>
                </div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
    export default {
        props: ['modelValue', 'value', 'title', 'hideTitle', 'loading'],
        data() {
            return {
                shown: this.modelValue || this.value,
                container: null
            }
        },
        methods: {
            closeModal(e) {
                this.shown = false;
                this.$emit('close');
            }
        },
        mounted() {
            this.container = this.$refs.modalContainer;
        },
        computed: {
            ...mapGetters(['numberOfModals'])
        },
        watch: {
            modelValue() {
                this.shown = this.modelValue;
            },
            value() {
                this.shown = this.value;
            },
            shown() {
                this.$emit('update:modelValue', this.shown);
                if(this.shown) {
                    this.$store.commit('numberOfModals', this.numberOfModals + 1);
                } else {
                    this.$store.commit('numberOfModals', this.numberOfModals - 1);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.modalGroup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    padding: 50px 0;
    pointer-events: none;
}

.modalGroupBg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0,0,0,0.3);
    opacity: 0;
    transition: ease 0.5s;
}

.modalGroupScroller {
    position: absolute;
    top: 0;
    right: 0;
    background: $bg;
    height: 100%;
    max-width: 550px;
    width: 100%;
    box-shadow: $boxShadow;
    overflow-y: auto;
    z-index: 999;
    transform: translateX(100%);
    transition: ease 0.5s;
}

.modalContainer {
    padding: 30px;
    position: relative;
}

.loaderHolder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.3);
    z-index: 2;
    border-radius: $borderRadius;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalTitle {
    display: flex;
    justify-content: space-between;
    height: 60px;
    align-items: center;
}

.closeBtn {
    background: transparent;
    color: $error;
    font-size: 20px;
    width: 34px;
    padding: 0;
}

</style>