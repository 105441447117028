import moment from 'moment';

export default {
    install: (app, options) => {


        app.config.globalProperties.$validateOnType = (type, value) => {
            console.log(type, value);
            value = 'bewh';
            return value;
        }


    }
  }